import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import { useTranslate, useEditContext } from 'react-admin';

const GdprDelete = () => {
    const translate = useTranslate();
    const { record: profile } = useEditContext();
    const [open, setOpen] = useState(false);

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleContinue = () => {
        // Handle the continue action here
        handleCloseDialog();
    };

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                sx={{ backgroundColor: 'red', marginBottom: 2 }}
                onClick={handleOpenDialog}
            >
                {translate('resources.student.actions.gdpr.button_title')}
            </Button>
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'lg'}
            >
                <DialogTitle id="alert-dialog-title">
                    {translate('resources.student.actions.gdpr.dialog.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {translate(
                            'resources.student.actions.gdpr.dialog.content',
                            {
                                name: `${profile?.public?.firstname} ${profile?.public?.lastname}`,
                                email: `${profile?.public?.email}`,
                            }
                        )}
                    </DialogContentText>
                    <DialogContentText sx={{ marginTop: 2 }}>
                        <Typography variant="body2">
                            {translate(
                                'resources.student.actions.gdpr.dialog.describe_service',
                                {
                                    service: `Cloubi`,
                                    id: `${profile?.guid}`,
                                }
                            )}
                        </Typography>
                    </DialogContentText>
                    <DialogContentText sx={{ marginTop: 0 }}>
                        <Typography variant="body2">
                            {translate(
                                'resources.student.actions.gdpr.dialog.describe_service',
                                {
                                    service: `Kirjavälitykselle`,
                                    id: `${profile?.username}`,
                                }
                            )}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDialog}
                        color="primary"
                        variant="outlined"
                        autoFocus
                    >
                        {translate(
                            'resources.student.actions.gdpr.dialog.cancel'
                        )}
                    </Button>
                    <Button
                        onClick={handleContinue}
                        color="error"
                        variant="contained"
                    >
                        {translate(
                            'resources.student.actions.gdpr.dialog.confirm'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GdprDelete;
